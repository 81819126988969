import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { useSelector } from "react-redux";
const initialState = {
  isLoading: false,
  caseList: [],
  submittedCases: [],
  todaySubmittedCases: JSON.parse(localStorage.getItem("todaySubmittedCases"))
    ? JSON.parse(localStorage.getItem("todaySubmittedCases"))
    : [],
  caseCreated: [],
  caseAssigned: [],
  pendingCases: [],
  readyReport: [],
  todayReadyReport: JSON.parse(localStorage.getItem("todayReadyReport"))
  ? JSON.parse(localStorage.getItem("todayReadyReport"))
  : [],
  currentCase: "",
  individualCase: "",
  isSelfieWithAgentLoading: true,
  selfieWithVehicleImage: "",
  isChasisImageLoading: true,
  chasisImage: "",
  IsformatImageLoading: true,
  formatImage: "",
  openBonnet: "",
  isOpenBonnetLoading: true,
  right45image: "",
  isRight45ImageLoading: true,
  frontimage: "",
  isFrontImageLoading: true,
  frontUnderCarImage: "",
  isFrontUnderCarImageLoading: true,
  left45image: "",
  isLeft45Loading: true,
  leftImage: "",
  isLeftImageLoading: true,
  rearImage: "",
  isRearImageLoading: true,
  rearUnderPartImage: "",
  isRearUnderPartLoading: true,
  openDickeyImage: "",
  isOpenDickeyImageLoading: true,
  rightImage: "",
  isRightImageLoading: true,
  tyreImage: "",
  isTyreImageLoading: true,
  glassOutsideImage: "",
  isGlassOutsideImageLoading: true,
  glassInsideImage: "",
  isGlassInsideImageLoading: true,
  odometerImage: "",
  isOdometerImageLoading: true,
  rpmImage: "",
  isRpmImageLoading: true,
  anyDamage: "",
  isAnyDamageLoading: true,
  extraImage: "",
  isExtraImageLoading: true,
  extraImage1: "",
  isExtraImage1Loading: true,
  extraImage2: "",
  isExtraImage2Loading: true,
  extraImage3: "",
  isExtraImage3Loading: true,
  signatureImage: "",
  isSignatureImageLoading: true,
  rcFrontImage: "",
  isRcFrontImageLoading: true,
  rcBackImage: "",
  isRcBackImageLoading: true,
};
export const createCase = createAsyncThunk(
  "case/createCase",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Case not able to create");
    }
  }
);
export const getAllCases = createAsyncThunk(
  "case/getAllCases",
  async (ID,thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/casesByclientID/${ID}`;
      console.log(url,"url");
      const resp = await axios.get(url);
      console.log(resp.data,"resp");
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Cases not able to fetch");
    }
  }
);
export const validateMobile = createAsyncThunk(
  "auth/validateNumber",
  async (number, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/mobile/${number}`;
      const resp = await axios.get(url);
      console.log(resp.data);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Mobile dosen't exist");
    }
  }
);
export const validateEmail = createAsyncThunk(
  "auth/valiadteEmail",
  async (email, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/email/${email}`;
      const resp = await axios.get(url);
      console.log(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Email no. dosen't exist");
    }
  }
);
export const getSinglecase = createAsyncThunk(
  "case/getSingleCase",
  async (_id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/cases/individualcase/${_id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (errro) {
      return thunkAPI.rejectWithValue("Case not able to fetch");
    }
  }
);
export const updateIndividualCase = createAsyncThunk(
  "case/updateCase",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: { "Content-Type": "application/json" },
      };
      const url = `${Baseurl}/api/v1/cases/updatecase/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Case not able to update");
    }
  }
);

const caseSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    setTodaySubmittedCases(state, action) {
      state.todaySubmittedCases = action.payload;
      localStorage.setItem("todaySubmittedCases", JSON.stringify(state.todaySubmittedCases));
    },
    setTodayReadyReport(state, action) {
      state.todayReadyReport = action.payload;
      localStorage.setItem("todayReadyReport", JSON.stringify(state.todayReadyReport));
    },
    setCurrCase(state, action) {
      state.currentCase = action.payload;
    },
    assignAgent(state, action) {
      state.assignedAgent = action.payload;
    },
    afterPost(state) {
      state.selfieWithVehicleImage = "";
      state.chasisImage = "";
      state.formatImage = "";
      state.openBonnet = "";
      state.right45image = "";
      state.frontimage = "";
      state.frontUnderCarImage = "";
      state.left45image = "";
      state.rearImage = "";
      state.rearUnderPartImage = "";
      state.openDickeyImage = "";
      state.rightImage = "";
      state.tyreImage = "";
      state.glassOutsideImage = "";
      state.glassInsideImage = "";
      state.odometerImage = "";
      state.rpmImage = "";
      state.anyDamage = "";
      state.signatureImage = "";
      state.rcFrontImage = "";
      state.rcBackImage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCase.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.caseList = [action.payload.case, ...state.caseList];
        }
        state.isLoading = false;
      })
      .addCase(createCase.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCases.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCases.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.caseCreated = action.payload.cases.filter(
            (individualCase) => individualCase.status === 1
          );
          state.readyReport = action.payload.cases.filter(
            (individualCase) => individualCase.status === 5
          );
          state.isLoading = false;
        }
      })
      .addCase(getAllCases.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIndividualCase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIndividualCase.fulfilled, (state, action) => {
        if (action.payload.case.status === 1) {
          state.caseCreated = state.caseCreated.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.caseCreated = [action.payload.case, ...state.caseCreated];
        }
        if (action.payload.case.status === 2) {
          state.caseCreated = state.caseCreated.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.caseAssigned = [action.payload.case, ...state.caseAssigned];
        }
        if (action.payload.case.status === 3) {
          state.caseAssigned = state.caseAssigned.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.pendingCases = [action.payload.case, ...state.pendingCases];
        }
        if (action.payload.case.status === 4) {
          state.pendingCases = state.pendingCases.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.submittedCases = [action.payload.case, ...state.submittedCases];
        }
        if (action.payload.case.status === 5) {
          state.submittedCases = state.submittedCases.filter(
            (individualCase) => individualCase._id === action.payload.case._id
          );
          state.readyReport = [action.payload.case, ...state.readyReport];
        }
        state.isLoading = false;
      })
      .addCase(updateIndividualCase.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getSinglecase.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSinglecase.fulfilled, (state, action) => {
        state.individualCase = action.payload.case;
        state.isLoading = false;
      })
      .addCase(getSinglecase.rejected, (state) => {
        state.isLoading = true;
      })
  },
});

export const CaseActions = caseSlice.actions;
export default caseSlice.reducer;
