import { configureStore } from "@reduxjs/toolkit";
import employeeReducer from "./Employee/employeeSlice";
import CasesReducer from "./Cases/CaseSlice";
import brandLogoReducer from "./Logo/BrandLogoSlice";
import orgLogoReducer from "./Logo/OrgLogoSlice";
import notificationReducer from "./Notifications/NotificationSlice";
import clientReducer from "./ClientAuthentication/ClientSlice";

export const store = configureStore({
  reducer: {
    employee: employeeReducer,
    cases: CasesReducer,
    brandLogo: brandLogoReducer,
    orgLogo: orgLogoReducer,
    notification:notificationReducer,
    clientAuth:clientReducer
  },
});
