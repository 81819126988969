import React from "react";

const PrivecyPolicy = () => {
  return (
    <>
      <div className="min-h-screen flex flex-col w-full bg-white ">
        <div className="p-6">
          <div className="flex items-center justify-between w-full">
            <h4 className="text-lg font-semibold text-default-900">
              Privecy Policy
            </h4>
          </div>

          <div className="p-5 border-default-200 pt-2">
            <label
              htmlFor="fName"
              className="text-sm font-medium text-default-900"
            >
              This privacy policy applies to the Instavaluer app (hereby
              referred to as "Application") for mobile devices that was created
              by Adsum Advisory Services Private Limited (hereby referred to as
              "Service Provider") as a Free service. This service is intended
              for use "AS IS".
            </label>
          </div>
          <div>
            <div className="flex items-center justify-between w-full">
              <h4 className="text-lg font-medium text-default-950 capitalize">
                Add Employee Details
              </h4>
            </div>
            <div className="p-5 border-default-200 pt-2">
              <label
                htmlFor="fName"
                className="text-sm font-medium text-default-900"
              >
                The Application collects information when you download and use
                it. This information may include information such as :
              </label>
              <ul className="p-5 disclaimer-list">
                <li className="disclaimer-list-li">
                  Your device's Internet Protocol address (e.g. IP address)
                </li>
                <li className="disclaimer-list-li">
                  The pages of the Application that you visit, the time and date
                  of your visit, the time spent on those pages
                </li>
                <li className="disclaimer-list-li">
                  The time spent on the Application
                </li>
                <li className="disclaimer-list-li">
                  The operating system you use on your mobile device
                </li>
              </ul>

              <label
                htmlFor="fName"
                className="text-sm font-medium text-default-900"
              >
                The Application collects your device's location, which helps the
                Service Provider determine your approximate geographical
                location and make use of in below ways:
              </label>
              <ul className="p-5 disclaimer-list">
                <li className="disclaimer-list-li">
                  Geolocation Services: The Service Provider utilizes location
                  data to provide features such as personalized content,
                  relevant recommendations, and location-based services.
                </li>
                <li className="disclaimer-list-li">
                  Analytics and Improvements: Aggregated and anonymized location
                  data helps the Service Provider to analyze user behavior,
                  identify trends, and improve the overall performance and
                  functionality of the Application.
                </li>
                <li className="disclaimer-list-li">
                  Third-Party Services: Periodically, the Service Provider may
                  transmit anonymized location data to external services. These
                  services assist them in enhancing the Application and
                  optimizing their offerings.
                </li>
              </ul>

              <label
                htmlFor="fName"
                className="text-sm font-medium text-default-900"
              >
                The Service Provider may use the information you provided to
                contact you from time to time to provide you with important
                information, required notices and marketing promotions.
              </label>
              <br />
              <label
                htmlFor="fName"
                className="text-sm font-medium text-default-900 pt-4"
              >
                For a better experience, while using the Application, the
                Service Provider may require you to provide us with certain
                personally identifiable information, including but not limited
                to First Name, Last Name, Mobile Number email ID. The
                information that the Service Provider request will be retained
                by them and used as described in this privacy policy.
              </label>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between w-full">
              <h4 className="text-lg font-medium text-default-950 capitalize">
                Third Party Access
              </h4>
            </div>
            <div className="p-5 border-default-200 pt-2">
              <label
                htmlFor="fName"
                className="text-sm font-medium text-default-900"
              >
                Only aggregated, anonymized data is periodically transmitted to
                external services to aid the Service Provider in improving the
                Application and their service. The Service Provider may share
                your information with third parties in the ways that are
                described in this privacy statement.
              </label>
              <label
                htmlFor="fName"
                className="text-sm font-medium text-default-900"
              >
                The Service Provider may disclose User Provided and
                Automatically Collected Information:
              </label>
              <ul className="p-5 disclaimer-list">
                <li className="disclaimer-list-li">
                  as required by law, such as to comply with a subpoena, or
                  similar legal process;
                </li>
                <li className="disclaimer-list-li">
                  when they believe in good faith that disclosure is necessary
                  to protect their rights, protect your safety or the safety of
                  others, investigate fraud, or respond to a government request.
                </li>
                <li className="disclaimer-list-li">
                  with their trusted services providers who work on their
                  behalf, do not have an independent use of the information we
                  disclose to them, and have agreed to adhere to the rules set
                  forth in this privacy statement.
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between w-full">
              <h4 className="text-lg font-medium text-default-950 capitalize">
                Opt-Out Rights
              </h4>
            </div>
            <div className="p-5 border-default-200 pt-2">
              <label
                htmlFor="fName"
                className="text-sm font-medium text-default-900"
              >
                You can stop all collection of information by the Application
                easily by uninstalling it. You may use the standard uninstall
                processes as may be available as part of your mobile device or
                via the mobile application marketplace or network.
              </label>
            </div>
          </div>        </div>
      </div>
    </>
  );
};

export default PrivecyPolicy;
