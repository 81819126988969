import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createEmployee, updateEmployee } from "../../redux/Employee/employeeSlice";
import { FileUploader } from "react-drag-drop-files";
import { CaseActions, UploadChasisImage, UploadFrontImage, UploadOpenBonnet, UploadRearUnderPart, UploadRight45Image, createCase, selfieWithVehicle, uploadAnyDamage, uploadFrontUnderCar, uploadGlassInsideImage, uploadGlassOutside, uploadLeft45, uploadLeftImage, uploadOdometerImage, uploadOpenDickey, uploadRcBackImage, uploadRcFront, uploadRearImage, uploadRightImage, uploadRpmImage, uploadSignature, uploadTyreImage, uploadformatImage, validateEmail, validateMobile, validateNumber } from "../../redux/Cases/CaseSlice";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CreateCase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { totalCars, loginData } = useSelector((store) => store.clientAuth);
  const { employeeList } = useSelector((store) => store.employee);
  const [caseType, setCaseType] = useState('');
  const [caseTypeError, setCaseTypeError] = useState('');
  const [assetType, setAssetType] = useState('');
  const [assetTypeError, setAssetTypeError] = useState('');
  const [valuationPurpose, setValuationPurpose] = useState('Assesment of fair market value');
  const [valuationPurposeError, setValuationPurposeError] = useState('');
  const [email, setEmail] = useState("");
  const [Cnfemail, setCnfEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailExistError, setEmailExistError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileCnf, setMobileCnf] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [mobileExistError, setMobileExistError] = useState("");
  const [requesterID, setRequesterID] = useState("");
  const [requesterName, setRequesterName] = useState("");
  const [requesterNameError, setRequesterNameError] = useState("");
  const [requesterBranch, setRequesterBranch] = useState("");
  const [requesterBranchError, setRequesterBranchError] = useState("");
  const [requesterPhone, setRequesterPhone] = useState("");
  const [requesterPhoneError, setRequesterPhoneError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [address, setAddress] = useState("")
  const [addressError, setAddressError] = useState("")
  const [state, setState] = useState("")
  const [stateError, setStateError] = useState("")
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState("")
  const [cityError, setCityError] = useState("")
  const [vehicleLocation, setVehicleLocation] = useState('')
  const [vehicleLocationError, setVehicleLocationError] = useState('')
  const [productBrand, setProductBrand] = useState("")
  const [productBrandError, setProductBrandError] = useState("")
  const [model, setModel] = useState("")
  const [ModelError, setModelError] = useState("")
  const [variant, setVariant] = useState("")
  const [variantError, setVariantError] = useState("")
  const [numberPlate, setNumberPlate] = useState("")
  const [bodyType, setBodyType] = useState("")
  const [fuelType, setFuelType] = useState("")
  const [regdNo, setRegdNo] = useState("")
  const [regdNoError, setRegdNoError] = useState("")
  const [backendEmployeesList, setBackendEmployeesList] = useState("")
  const [backendEmployee, setBackendEmployees] = useState("")
  const [managerList, setManagerList] = useState("")
  const [manager, setManager] = useState("")
  const [uniqueNames, setUniqueNames] = useState([]);
  const [uniqueType, setUniqueType] = useState([]);
  const [vehicleData, setVehicleData] = useState({});
  const [dataFetched, setDataFetched] = useState(false)
  const [role, setRole] = useState("")

  const [btnPress, setbtnPress] = useState(false);
  const selectCaseType = [
    {
      id: 1,
      name: "Revaluation",
    },
    {
      id: 2,
      name: "Repossession",
    },
    {
      id: 3,
      name: "Retail",
    },
    {
      id: 4,
      name: "Insurance Idv",
    },
    {
      id: 5,
      name: "Repurchase",
    },
  ];


  const stateList = [
    { id: 1, name: "Andhra Pradesh" },
    { id: 2, name: "Arunachal Pradesh" },
    { id: 3, name: "Assam" },
    { id: 4, name: "Bihar" },
    { id: 5, name: "Chhattisgarh" },
    { id: 6, name: "Goa" },
    { id: 7, name: "Gujarat" },
    { id: 8, name: "Haryana" },
    { id: 9, name: "Himachal Pradesh" },
    { id: 10, name: "Jharkhand" },
    { id: 11, name: "Karnataka" },
    { id: 12, name: "Kerala" },
    { id: 13, name: "Madhya Pradesh" },
    { id: 14, name: "Maharashtra" },
    { id: 15, name: "Manipur" },
    { id: 16, name: "Meghalaya" },
    { id: 17, name: "Mizoram" },
    { id: 18, name: "Nagaland" },
    { id: 19, name: "Odisha" },
    { id: 20, name: "Punjab" },
    { id: 21, name: "Rajasthan" },
    { id: 22, name: "Sikkim" },
    { id: 23, name: "Tamil Nadu" },
    { id: 24, name: "Telangana" },
    { id: 25, name: "Tripura" },
    { id: 26, name: "Uttar Pradesh" },
    { id: 27, name: "Uttarakhand" },
    { id: 28, name: "West Bengal" },
    { id: 29, name: "Andaman and Nicobar Islands" },
    { id: 30, name: "Chandigarh" },
    { id: 31, name: "Dadra and Nagar Haveli and Daman and Diu" },
    { id: 32, name: "Delhi" },
    { id: 33, name: "Ladakh" },
    { id: 34, name: "Lakshadweep" },
    { id: 35, name: "Puducherry" }
  ];
  const selectStateChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setState(text);
  };
  useEffect(() => {
    if (state) {
      const formData = {
        country: 'India',
        state: state.toLocaleUpperCase(),
      };
      const getCity = async () => {
        try {
          const config = {
            headers: { 'Content-Type': 'application/json' },
          };
          const url = 'https://countriesnow.space/api/v0.1/countries/state/cities';
          const resp = await axios.post(url, formData, config);
          console.log("Response:", resp.data); // Log the response data for debugging
          if (!resp.data.error) {
            const citiesData = resp.data.data.map((city, index) => ({
              id: index + 1,
              name: city.toLowerCase().replace(/\s/g, '_'),
            }));
            setCityList(citiesData);
          } else {
            console.error("Error in response:", resp.data.error); // Log the error for debugging
          }
        } catch (error) {
          console.error("Error:", error); // Log any other errors for debugging
        }
      };

      getCity();
    }
  }, [state]);

  const selectCityChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCity(text);
  };
  const selectCaseChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setCaseType(text);
  };
  const selectAssetType = [
    {
      id: 1,
      name: "Two Wheeler",
    },
    {
      id: 2,
      name: "Four Wheeler",
    },
    {
      id: 3,
      name: "Commercial",
    },
    {
      id: 4,
      name: "Construction Equipment",
    },
    {
      id: 5,
      name: "Three Wheeler",
    },
    {
      id: 6,
      name: "Farm Equipment",
    },
  ];
  const selectAssetChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setAssetType(text);
  };

  const fuel = [
    { id: 1, name: 'Petrol' },
    { id: 2, name: 'Diesel' },
    { id: 3, name: 'Ev' },
  ];

  const selectFuelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setFuelType(text);
  };

  const fourWheelerBody = [
    { name: 'Salsoon', id: 1 },
    { name: 'Sedans', value: 2 },
    { name: 'Hatchbacks', value: 3 },
    { name: 'SUVs', value: 4 },
    { name: 'MUVs', value: 5 },
    { name: 'Coupes', value: 6 },
  ];
  const constructionBody = [
    { name: 'Motor Graders', id: 1 },
    { name: 'Trenchers', id: 2 },
    { name: 'Excavators', id: 3 },
    { name: 'Common Dump Trucks', id: 4 },
    { name: 'Crawler Loaders', id: 5 },
    { name: 'Skid Steer Loaders', id: 6 },
    { name: 'Scrapers', id: 7 },
    { name: 'Bulldozers', id: 8 },
    { name: 'Backhoe Loader', id: 9 },
  ];
  const twoWheelerBody = [
    { name: 'standard', id: 1 },
    { name: 'cruiser', id: 2 },
    { name: 'touring', id: 3 },
    { name: 'sports', id: 4 },
    { name: 'off-road', id: 5 },
    { name: 'dual-purpose', id: 6 },
  ];

  const selectBodyTypeChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setBodyType(text);
  };



  useEffect(() => {
    const uniqueNamesArray = [];
    const uniquesTypesArray = []
    const flattenedArray = totalCars.reduce((acc, currentArray) => {
      return acc.concat(currentArray);
    }, []);
    flattenedArray.forEach(car => {
      if (!uniqueNamesArray.includes(car.Make)) {
        uniqueNamesArray.push(car.Make);
      }
      if (!uniquesTypesArray.includes(car.Type)) {
        uniquesTypesArray.push(car.Type)
      }
    });
    setUniqueNames(uniqueNamesArray);
    setUniqueType(uniquesTypesArray)
  }, [totalCars]);

  const selectProductBrandChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setProductBrand(text);
  };
  const selectProductModelChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setModel(text);
  };
  const selectProductVariantChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setVariant(text);
  };
  const numberPlateList = [
    { id: 1, name: 'White' },
    { id: 2, name: 'Yellow ' },
    { id: 3, name: 'Blue ' },
    { id: 4, name: 'Black ' },
  ];
  const selectNumberPlateChange = (e) => {
    const { value } = e.target;
    const index = e.nativeEvent.target.selectedIndex;
    const text = e.nativeEvent.target[index].text;
    setNumberPlate(text);
  };

  useEffect(() => {
    if (btnPress === true) {
      if (assetType === '') {
        setAssetTypeError('Please enter asset type')
      } else {
        setAssetTypeError('')
      }
      if (caseType === '') {
        setCaseTypeError('Please enter case type')
      } else {
        setCaseTypeError('')
      }
      if (valuationPurpose === '') {
        setValuationPurposeError('Please enter valuation purpose')
      } else {
        setValuationPurposeError('')
      }
      if (requesterName === '') {
        setRequesterNameError('Please enter valuation purpose')
      } else {
        setRequesterNameError('')
      }
      if (requesterBranch === '') {
        setRequesterBranchError('Please enter valuation purpose')
      } else {
        setRequesterBranchError('')
      }
      if (requesterPhone === '') {
        setRequesterPhoneError('Please enter valuation purpose')
      } else {
        setRequesterPhoneError('')
      }
      if (name === "") {
        setNameError("please enter title");
      } else {
        setNameError("");
      }
      if (Cnfemail === "") {
        setEmailError("please enter email");
      } else {
        setEmailError("");
      }
      if (mobileCnf.length !== 10) {
        setMobileError("please enter mobile");
      } else {
        setMobileError("");
      }
      if (name === "") {
        setNameError("Please enter client name")
      } else {
        setNameError("")
      }
      if (address === "") {
        setAddressError("Please enter address")
      } else {
        setAddressError("")
      }
      if (state === "") {
        setStateError("Please enter state name")
      } else {
        setStateError("")
      }
      if (city === "") {
        setCityError("Please enter city name ")
      } else {
        setCityError("")
      }
      if (productBrand === "") {
        setProductBrandError("Please enter product brand name")
      } else {
        setProductBrandError("")
      }
      if (model === "") {
        setModelError("Please enter product model")
      }
      else {
        setModelError("")
      }
      if (variant === "") {
        setVariantError("Please select variant of the product")
      } else {
        setVariantError("")
      }
      if (regdNo === "") {
        setRegdNoError("Please enter reg no. of the product")
      } else {
        setRegdNoError("")
      }
    }
  }, [btnPress, assetType, caseType, valuationPurpose, requesterName, requesterBranch, requesterPhone, name, Cnfemail, vehicleLocation,
    mobileCnf, name, address, state, city, productBrand,
    model, variant, regdNo,
  ]);

  useEffect(() => {
    const getEmployeeList = employeeList.filter((individualemployee) =>
      individualemployee.role === "agent"
    )
    if (getEmployeeList) {
      setBackendEmployees(getEmployeeList)
    }
    const getManagerList = employeeList.filter((individualManager) =>
      individualManager.role === "manager"
    )
    if (getManagerList) {
      setManagerList(getManagerList)
    }
  }, [])

  const handleRequesterPhn = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setRequesterPhone(value)
  }
  const setNumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileCnf(value);
  };
  const emailChange = async (e) => {
    const value = e;
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setEmail(value);
    setEmailError("");
    setEmailExistError('')
    if (value.length > 0) {
      if (value.match(mailformat)) {
        setCnfEmail(value);
      } else {
        setEmailError("please enter correct Email format");
      }
    } else {
      setEmailError("please enter correct Email format");
    }
  };

  const cancelClick = async (e) => {
    e.preventDefault();
    setAssetType('');
    setCaseType('')
    setValuationPurpose('')
    setEmail("");
    setCnfEmail("");
    setMobile("");
    setMobileCnf("");
    setName("");
    setVehicleLocation("");
    setAddress("");
    setState("");
    setCity("")
    setProductBrand("")
    setModel("")
    setVariant("")
    setRegdNo("")
  };
  const [error, setError] = useState(null);

  const fetchVehicleData = async (vehicleNumber) => {
    try {
      const data = JSON.stringify({ "vehicleNumber": vehicleNumber });
      const config = {
        method: 'post',
        url: 'https://api.invincibleocean.com/invincible/vehicleRcV6',
        headers: {
          'Content-Type': 'application/json',
          'clientId': '7383c6f956c453a5689f4da6843b9f54:a3c36d6f8cc8dcf5ad904e5ab22150a0',
          'secretKey': 'fvCtpjNbHpAVgoJiZJZPWNIjLsJO3VYqem6PsrENWSv54TYqehEomZCUtIwwegRT9'
        },
        data: data
      };

      const response = await axios(config);
      const newData = response.data.result.data;

      // Check if required properties are not null
      if (
        newData.rcFinancer &&
        newData.vehicleTaxUpto &&
        newData.permitValidUpto &&
        newData.permitType &&
        newData.vehicleSeatCapacity &&
        newData.grossVehicleWeight &&
        newData.regAuthority
      ) {
        return newData;
      } else {
        throw new Error('Required data is missing or null');
      }
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
      throw new Error('Failed to fetch vehicle data');
    }
  };

  const handleRegdNo = async (e) => {
    const inputValue = e.target.value.toUpperCase();
    setRegdNo(inputValue);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    if (assetType === '') {
      setAssetTypeError('Please enter asset type')
    } else {
      setAssetTypeError('')
    }
    if (caseType === '') {
      setCaseTypeError('Please enter case type')
    } else {
      setCaseTypeError('')
    }
    if (valuationPurpose === '') {
      setValuationPurposeError('Please enter valuation purpose')
    } else {
      setValuationPurposeError('')
    }
    if (requesterName === '') {
      setRequesterNameError('Please enter valuation purpose')
    } else {
      setRequesterNameError('')
    }
    if (requesterBranch === '') {
      setRequesterBranchError('Please enter valuation purpose')
    } else {
      setRequesterBranchError('')
    }
    if (requesterPhone === '') {
      setRequesterPhoneError('Please enter valuation purpose')
    } else {
      setRequesterPhoneError('')
    }
    if (name === "") {
      setNameError("please enter title");
    } else {
      setNameError("");
    }
    if (Cnfemail === "") {
      setEmailError("please enter email");
    } else {
      setEmailError("");
    }
    if (mobileCnf.length !== 10) {
      setMobileError("please enter mobile");
    } else {
      setMobileError("");
    }
    if (name === "") {
      setNameError("Please enter client name")
    } else {
      setNameError("")
    }
    if (address === "") {
      setAddressError("Please enter address")
    } else {
      setAddressError("")
    }
    if (state === "") {
      setStateError("Please enter state name")
    } else {
      setStateError("")
    }
    if (city === "") {
      setCityError("Please enter city name ")
    } else {
      setCityError("")
    }
    if (productBrand === "") {
      setProductBrandError("Please enter product brand name")
    } else {
      setProductBrandError("")
    }
    if (model === "") {
      setModelError("Please enter product model")
    }
    else {
      setModelError("")
    }
    if (variant === "") {
      setVariantError("Please select variant of the product")
    } else {
      setVariantError("")
    }
    if (regdNo === "") {
      setRegdNoError("Please enter reg no. of the product")
    } else {
      setRegdNoError("")
    }
    if (name !== "" && Cnfemail !== "" && mobileCnf !== ""
      && address !== "" && requesterName !== '' && requesterBranch !== '' && requesterPhone !== ''
      && state !== "" && city !== "" && regdNo !== ""
    ) {
      const formData = {
        assetType: assetType,
        caseType: caseType,
        valuationPurpose: valuationPurpose,
        vehicleLocation: vehicleLocation,
        requesterID: loginData._id,
        requesterName: requesterName,
        requesterBranch: requesterBranch,
        requesterPhone: requesterPhone,
        name: name,
        mobile: mobileCnf,
        email: Cnfemail,
        address: address,
        state: state,
        city: city,
        regdNo: regdNo,
        brand: productBrand,
        model: model,
        variant: variant,
      };
      console.log(formData,'formData');      
      const newcase = await dispatch(createCase(formData))
      if (newcase.payload.success) {
        alert("Case created successfully");
        setEmail("");
        setCnfEmail("");
        setMobile("");
        setMobileCnf("");
        setName("");
        setRole("")
        setAddress("")
        setState("")
        setCity("")
        setProductBrand("")
        setModel("")
        setVariant("")
        setRegdNo("")
        setRequesterName('')
        setRequesterPhone('')
        setRequesterBranch('')
        setbtnPress(false)
        navigate("/client")
      }
    } else {
      setbtnPress(true);
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-6 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Create Case
            </h4>
          </div>
          <div className="shadow rounded-lg bg-white dark:bg-default-50 h-fit">
            <div className="flex items-center justify-between py-4 px-5">
              <h4 className="text-lg font-medium text-default-950 capitalize">
                Create Case
              </h4>
            </div>
            <div className="p-5 border-t border-dashed border-default-200">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Case Type *
                  </label>
                  <select
                    className={
                      caseTypeError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="choices-single-default"
                    placeholder="This is a search placeholder"
                    value={selectCaseType.id}
                    name={selectCaseType.name}
                    onChange={(e) => selectCaseChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Case Type
                    </option>
                    {selectCaseType.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fAssetType"
                    className="text-sm font-medium text-default-900"
                  >
                    Asset Type *
                  </label>
                  <select
                    className={
                      assetTypeError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="fAssetType"
                    placeholder="This is a search placeholder"
                    value={selectAssetType.id}
                    name={selectAssetType.name}
                    onChange={(e) => selectAssetChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose Asset Type
                    </option>
                    {selectAssetType.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fValuationPurpose"
                    className="text-sm font-medium text-default-900"
                  >
                    Purpose Of Valuation *
                  </label>
                  <input
                    type="text"
                    className={
                      valuationPurposeError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fValuationPurpose"
                    placeholder="Enter Valuation Purpose"
                    value={valuationPurpose}
                    onChange={(e) => setValuationPurpose(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Valuation Requested By *
                  </label>
                  <input
                    type="text"
                    className={
                      requesterNameError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fName"
                    placeholder="Enter Requester Name"
                    value={requesterName}
                    onChange={(e) => setRequesterName(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Branch Name *
                  </label>
                  <input
                    type="text"
                    className={
                      requesterBranchError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fName"
                    placeholder="Enter Requester Branch"
                    value={requesterBranch}
                    onChange={(e) => setRequesterBranch(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Requester Phone *
                  </label>
                  <input
                    type="text"
                    className={
                      requesterPhoneError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="lName"
                    placeholder="Enter Requester Phone"
                    minLength={10}
                    maxLength={10}
                    value={requesterPhone}
                    onChange={(e) => handleRequesterPhn(e)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Name *
                  </label>
                  <input
                    type="text"
                    className={
                      nameError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="fName"
                    placeholder="Enter Customer Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Phone *
                  </label>
                  <input
                    type="text"
                    className={
                      mobileError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="lName"
                    placeholder="Enter Customer Phone"
                    minLength={10}
                    maxLength={10}
                    value={mobileCnf}
                    onChange={(e) => setNumberChange(e)}
                  />
                  <div>{mobileExistError}</div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="lName"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Email *
                  </label>
                  <input
                    type="text"
                    className={
                      emailError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="rollNo"
                    placeholder="Enter Customer Email"
                    value={email}
                    onChange={(e) => emailChange(e.target.value)}
                  />
                  <div>{emailExistError}</div>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="Address"
                    className="text-sm font-medium text-default-900"
                  >
                    Customer Address *
                  </label>
                  <input
                    type="text"
                    className={
                      addressError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="Address"
                    placeholder="Enter Customer Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="fState"
                    className="text-sm font-medium text-default-900"
                  >
                    Select State *
                  </label>
                  <select
                    className={
                      stateError === ""
                        ? `border-default-200 w-full`
                        : `border-default-200 w-full error_class`
                    }
                    data-trigger
                    id="fState"
                    placeholder="This is a search placeholder"
                    value={stateList.id}
                    name={stateList.name}
                    onChange={(e) => selectStateChange(e)}
                  >
                    <option value="0" hidden selected>
                      Choose State Type
                    </option>
                    {stateList.map((test, index) => (
                      <option
                        key={index}
                        value={test.id}
                        name={test.name}
                        required
                      >
                        {test.name}
                      </option>
                    ))}
                  </select>
                </div>
                {
                  cityList.length !== 0 &&
                  <div className="space-y-1">
                    <label
                      htmlFor="fCity"
                      className="text-sm font-medium text-default-900"
                    >
                      Select City *
                    </label>
                    <select
                      className={
                        cityError === ""
                          ? `border-default-200 w-full`
                          : `border-default-200 w-full error_class`
                      }
                      data-trigger
                      id="fCity"
                      placeholder="This is a search placeholder"
                      value={cityList.id}
                      name={cityList.name}
                      onChange={(e) => selectCityChange(e)}
                    >
                      <option value="0" hidden selected>
                        Choose City Type
                      </option>
                      {cityList.map((test, index) => (
                        <option
                          key={index}
                          value={test.id}
                          name={test.name}
                          required
                        >
                          {test.name}
                        </option>
                      ))}
                    </select>
                  </div>
                }
                <div className="space-y-1">
                  <label
                    htmlFor="regdNo"
                    className="text-sm font-medium text-default-900"
                  >
                    RegdNo *
                  </label>
                  <input
                    type="text"
                    className={
                      regdNoError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="regdNo"
                    placeholder="Enter Regd No"
                    value={regdNo}
                    onChange={(e) => handleRegdNo(e)}
                  />
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="vehcileLocation"
                    className="text-sm font-medium text-default-900"
                  >
                    Vehicle Location *
                  </label>
                  <input
                    type="text"
                    className={
                      vehicleLocationError !== ""
                        ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                        : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                    }
                    id="vehcileLocation"
                    placeholder="Enter Vehcile Location"
                    value={vehicleLocation}
                    onChange={(e) => setVehicleLocation(e.target.value)}
                  />
                </div>
                <div className=" space-y-2">
                  <div className="w-full">
                    <div className="mb-2">
                      <label
                        htmlFor="choices-vehicle-brand"
                        className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                      >
                        Choose Vehicle Brand *
                      </label>
                    </div>
                    <select
                      className={
                        productBrandError !== ""
                          ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                          : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                      }
                      data-trigger
                      id="choices-vehicle-brand"
                      placeholder="This is a search placeholder"
                      value={totalCars._id}
                      name={totalCars.Make}
                      onChange={(e) => selectProductBrandChange(e)}
                    >
                      <option value="0" hidden selected>
                        Choose Vehicle Brand
                      </option>
                      {uniqueNames.map((Make, index) => (
                        <option
                          selected={Make === productBrand}
                          key={index}
                          value={Make}
                          name={Make}
                          required
                        >
                          {Make}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className=" space-y-2">
                  <div className="w-full">
                    <div className="mb-2">
                      <label
                        htmlFor="choices-vehicle-model"
                        className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                      >
                        Choose Vehicle Model *
                      </label>
                    </div>
                    <select
                      className={
                        ModelError !== ""
                          ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                          : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                      }
                      data-trigger
                      id="choices-vehicle-model"
                      placeholder="This is a search placeholder"
                      value={totalCars._id}
                      name={totalCars.Model}
                      onChange={(e) => selectProductModelChange(e)}
                    >
                      <option value="0" hidden selected>
                        Choose Vehicle Model
                      </option>
                      {totalCars
                        .filter((car) => car.Make === productBrand)
                        .filter(
                          (car, index, self) =>
                            self.findIndex((c) => c.Model === car.Model) ===
                            index
                        ) // Filter out duplicates
                        .map((car, index) => (
                          <option
                            selected={car.Model === model}
                            key={index}
                            value={car.Model}
                            name={car.Model}
                            required
                          >
                            {car.Model}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className=" space-y-2">
                  <div className="w-full">
                    <div className="mb-2">
                      <label
                        htmlFor="choices-vehicle-variant"
                        className="form-label text-sm font-medium text-default-800 dark:text-zinc-100 studenth4-label"
                      >
                        Choose Vehicle Variant *
                      </label>
                    </div>
                    <select
                      className={
                        variantError !== ""
                          ? "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50 error_class"
                          : "block w-full rounded py-2.5 px-4 text-default-800 text-sm focus:ring-transparent border-default-200 dark:bg-default-50"
                      }
                      data-trigger
                      id="choices-vehicle-variant"
                      placeholder="This is a search placeholder"
                      value={totalCars.id}
                      name={totalCars.name}
                      onChange={(e) => selectProductVariantChange(e)}
                    >
                      <option value="0" hidden selected>
                        Choose vehicle variant
                      </option>
                      {totalCars.map((test, index) => {
                        if (
                          test.Make === productBrand &&
                          test.Model === model
                        ) {
                          return (
                            <option
                              selected={test.Variant === variant}
                              key={index}
                              value={test.id}
                              name={test.Variant}
                              required
                            >
                              {test.Variant}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="flex items-center gap-2 justify-end"
            style={{ marginBottom: "60px" }}
          >
            <button
              onClick={(e) => cancelClick(e)}
              style={{
                background: "#ef4444",
                color: "#fff",
                border: "none",
                outline: "none",
              }}
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
            >
              <i className="ph-bold ph-x text-lg" /> Reset
            </button>
            <button
              className="py-2 px-5 inline-flex items-center justify-center gap-2 font-semibold tracking-wide border align-middle duration-500 text-sm text-center bg-default-800 hover:bg-default-950  text-default-50 rounded"
              style={{
                background: "#8481ff",
                border: "none",
                outline: "none",
              }}
              onClick={(e) => handlesubmit(e)}
            >
              <i className="ph-duotone ph-cloud-arrow-up text-lg" />
              Create
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCase;
