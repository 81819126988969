import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const ReadyReport = () => {
  const { readyReport, assignedAgent } = useSelector((store) => store.cases);

  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [urlPath, setUrlPath] = useState('')

  useEffect(() => {
    setFilterData(readyReport);
  }, [search, readyReport]);

  const handleReport = (admin) => {
    const assetType = admin.assetType;
    if (assetType === 'Four Wheeler') {
      navigate(`/releasereport/${admin._id}`)
    } else if (assetType === 'Two Wheeler') {
      navigate(`/twowheelerreportrelease/${admin._id}`)
    } else if (assetType === 'Construction Equipment') {
      navigate(`/constructionreportrelease/${admin._id}`)
    } else if (assetType === 'Commercial') {
      navigate(`/commercialreportrelease/${admin._id}`)
    } else if (assetType === 'Three Wheeler') {
      navigate(`/threewheelerreportrelease/${admin._id}`)
    } else if (assetType === 'Farm Equipment') {
      navigate(`/firmequipmentreportrelease/${admin._id}`)
    }

  }

  return (
    <>
      <div className="min-h-screen flex flex-col lg:ps-64 w-full">
        <div className="p-6 space-y-6">
          <div className="flex items-center justify-between w-full print:hidden">
            <h4 className="text-lg font-semibold text-default-900">
              Dashboard
            </h4>
          </div>
        </div>
        <div className="grid grid-cols-1" style={{ marginBottom: '60px' }}>
          <div className="shadow rounded-lg bg-white dark:bg-default-50">
            <div className="border-t border-dashed border-default-200">
              <div className="relative overflow-x-auto">
                <table className="min-w-full overflow-x-hidden">
                  <thead className="border-b border-dashed border-default-200">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-16"
                      >
                        No
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-40"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Mobile
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Hypothecation Bank
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Vehicle Preview
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        Signature
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-center text-sm capitalize font-semibold text-default-900 min-w-20"
                      >
                        View
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-dashed divide-default-200">
                    {readyReport &&
                      readyReport.map((individualReport, index) => (
                        <tr key={index}>
                          <td className="px-6 py-3 text-center text-default-600 font-semibold whitespace-nowrap">
                            <b>{index + 1}</b>
                          </td>
                          <td className="px-6 py-3 text-center whitespace-nowrap">
                            <h6 className="text-sm font-semibold text-default-700">
                              {individualReport.name.length > 30 ? (
                                <>{individualReport.name.slice(0, 30)}...</>
                              ) : (
                                <>{individualReport.name.slice(0, 30)}</>
                              )}
                            </h6>
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                            {individualReport.mobile}
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                            {individualReport.email.length > 18 ? (
                              <>{individualReport.email.slice(0, 18)}...</>
                            ) : (
                              <>{individualReport.email.slice(0, 18)}</>
                            )}
                          </td>
                          <td className="px-6 py-3 text-center text-default-600 font-medium whitespace-nowrap">
                            <div className="d-flex m-auto">
                              {individualReport.hypothecationBank}
                            </div>
                          </td>
                          <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap">
                            <div className="d-flex m-auto">
                              <img
                                src={individualReport.images.frontImage}
                                style={{ height: "50px", width: "80px" }}
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="px-6 py-3 text-default-600 font-medium whitespace-nowrap">
                            <div className="d-flex m-auto">
                              <img
                                src={individualReport.signNdoc.sign}
                                style={{ height: "50px", width: "80px" }}
                                alt=""
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap py-3 px-3 text-center text-sm font-medium">
                            <div className="flex items-center justify-center gap-2">
                              <a className="inline-flex items-center justify-center p-3 rounded-md bg-cyan-500/20 text-cyan-500 transition-all duration-200 hover:bg-cyan-500 hover:text-white"
                              target="_blank"
                              href={individualReport.reportUrl}
                              >
                                View Report
                              </a>
                              {/* <button className="inline-flex items-center justify-center h-8 w-8 rounded-md bg-red-500/20 text-red-500 transition-all duration-200 hover:bg-red-500 hover:text-white">
                                  <i className="ph-duotone ph-trash text-base" />
                                </button> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-between gap-6 py-3 px-6 border-t border-dashed border-default-200">
              {/* <h6 className="text-default-600">Showing 1 to 5 of 12</h6> */}
              {/* <nav className="flex items-center gap-1">
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-left text-base" />
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border rounded-md transition-all duration-200 bg-primary text-white border-primary"
                                    to="#"
                                    aria-current="page"
                                >
                                    1
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    2
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    ...
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    12
                                </a>
                                <a
                                    className="inline-flex items-center justify-center h-8 w-8 border border-default-200 rounded-md text-default-950 transition-all duration-200 hover:bg-primary hover:text-white hover:border-primary"
                                    to="#"
                                >
                                    <i className="ph ph-caret-right text-base" />
                                </a>
                            </nav> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadyReport;
