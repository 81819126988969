import React, { useEffect, useState, CSSProperties } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { FiDownload } from "react-icons/fi";
import { Baseurl } from '../../config/BaseUrl';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";

function ImageReport() {
    const params = useParams();
    const [vehicleNo, setVehicleNo] = useState("");
    const [images, setImages] = useState({})
    const [tempLoading, setTempLoading] = useState(true)
    const [color, setColor] = useState("red");
    let [loading, setLoading] = useState(true);
    useEffect(() => {
        const caseNo = params.caseId;
        const fetchData = async () => {
            try {
                const url = `${Baseurl}/api/v1/cases/individualcase/${caseNo}`;
                const caseFetch = await axios.get(url);
                try {
                    if (caseFetch.data.success) {
                        const casedetails = caseFetch.data.case;
                        setVehicleNo(casedetails.regdNo)
                        setImages(casedetails.images)
                        setTempLoading(false)
                    } else {
                        alert('Images are not able to fetch')
                    }
                } catch (error) { }
            } catch (error) { }
        }
        fetchData()
    }, [params.caseId])


    const downloadImage = (image, imagename) => {
        // window.open(selfieWithVehicle, '_blank').focus();
        saveAs(image, imagename)
    }

    return (
        <>
            {
                tempLoading === true ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:'100vh' }}>
                    <ClipLoader
                        color={color}
                        loading={loading}
                        // cssOverride={override}
                        size={50}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>) : (<>
                    <div className="container-fluid-lg bg-white">
                        <div
                            style={{
                                backgroundColor: '#E2DFD2',
                                height: '64px',
                                textAlign: 'center',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '28px'
                            }}>
                            <div>
                                <h1 className='text-dark'>Adsum Advisory Services Pvt. Ltd.</h1>
                            </div>
                        </div>
                        <div className="container-fluid mt-5">
                            <div id="heading">
                                <h5 style={{ fontSize: "" }} className='text-center'>Images of Vehicle No: {vehicleNo}</h5> <br />
                                <h2 style={{ color: '#FF0000', fontSize: 'large' }} className=' text-center'>(just Click on any image to download...)</h2>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap', margin: '5px' }}>
                                {Object.keys(images).map((key, index) => (
                                    <div key={index} className="card m-2" style={{ margin: '5px', textTransform: 'uppercase', position: 'relative' }}>
                                        <img height={"300px"} width={"300px"} src={images[key]} alt={`Image ${index}`} className="img-fluid" />
                                        <div onClick={(e) => downloadImage(images[key], `${key}.png`)} className='download-image'>
                                            <FiDownload />
                                        </div>
                                        <div style={{ textAlign: 'center', fontSize: '16px' }}>
                                            {key}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>)
            }

        </>
    )
}

export default ImageReport
