import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";

const initialState = {
  isLoading: false,
  employeeList: [],
  individualEmployee: {},
};

export const createEmployee = createAsyncThunk(
  "employee/employeePost",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/employees/new`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error in Posting employee data ");
    }
  }
);

export const getAllEmployee = createAsyncThunk(
  "employee/allEmployee",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/employees/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error in fetching Data");
    }
  }
);

export const getIndividualEmployee = createAsyncThunk(
  "employee/individualEmployee",
  async (_id, thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/employees/individualemployee/${_id}`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error in fetching Data");
    }
  }
);

export const updateEmployee = createAsyncThunk(
  "employee/updateEmployee",
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/employees/updateemployee/${formData._id}`;
      const resp = await axios.put(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Error in updating Data");
    }
  }
);

export const mobileExists=createAsyncThunk(
  "employees/EmployeeMobile",
  async(mobile,thunkAPI)=>{
    try{
      const config={
        Headers: {
          "content-type": "application/json",
        },
      }
      const url = `${Baseurl}/api/v1/employees/mobile/${mobile}`;
      const resp = await axios.get(url);
      console.log(resp.data);
      return resp.data;
    }catch(error){
      return thunkAPI.rejectWithValue('Mobile no. already exists')
    }
  }
)
export const emailExists=createAsyncThunk(
  "employees/EmployeeEmail",
  async(email,thunkAPI)=>{
    try{
      const config={
        Headers: {
          "content-type": "application/json",
        },
      }
      const url = `${Baseurl}/api/v1/employees/email/${email}`;
      const resp = await axios.get(url);
      console.log(resp.data);
      return resp.data;
    }catch(error){
      return thunkAPI.rejectWithValue('Email already exists')
    }
  }
)
const employeeSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.employeeList = [action.payload.employee, ...state.employeeList];
        }
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.employeeList = action.payload.employees;
      })
      .addCase(getAllEmployee.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(getIndividualEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIndividualEmployee.fulfilled, (state, action) => {
        state.individualEmployee = action.payload.employee;
      })
      .addCase(getIndividualEmployee.rejected, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEmployee.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        if (action.payload.success) {
          state.employeeList = state.employeeList.filter((employee) => 
            employee._id !== action.payload.employee._id
          );
          state.employeeList = [action.payload.employee, ...state.employeeList];
          state.isLoading = false;
        }
      })
      .addCase(updateEmployee.rejected, (state) => {
        state.isLoading = true;
      });
  },
});

export const authActions = employeeSlice.actions;
export default employeeSlice.reducer;
