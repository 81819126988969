import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

import axios from 'axios';
import { Baseurl } from '../../config/BaseUrl';

const initialState = {
  isAuth: JSON.parse(localStorage.getItem("clientLoginData"))?.isAuth
  ? JSON.parse(localStorage.getItem("clientLoginData")).isAuth
  : false,
  clientId: '',
  name: JSON.parse(localStorage.getItem("clientLoginData"))?.name
    ? JSON.parse(localStorage.getItem("clientLoginData")).name
    : "",
  role: '',
  loginData: localStorage.getItem("clientLoginData")
    ? JSON.parse(localStorage.getItem("clientLoginData"))
    : "",
  email: '',
  availibilityStatus: false,
  brandLogo: '',
  adminLoading:true,
  clientLoading:true,
  totalCars:[],
  isCarLoading:false
};

export const employeeLoginBypassword = createAsyncThunk(
  'employee/employeeLoginBypassword',
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {'Content-Type': 'application/json'},
      };
      const url = `${Baseurl}/api/v1/clients/loginwithpassword`;
      console.log(url,'url');     
      console.log(formData,'formData');
      const resp = await axios.post(url, formData, config);
      console.log(resp.data,"resp.data");
      return resp.data;
    } catch (error) {
      return error.response.data;
    }
  },
);

export const getCarModels = createAsyncThunk(
  "car/carModels",
  async (thunkAPI) => {
    try {
      const config = {
        Headers: {
          "content-type": "application/json",
        },
      };
      const url = `${Baseurl}/api/v1/car/all`;
      const resp = await axios.get(url);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);


export const employeeLogin = createAsyncThunk(
  'Athentication/employeeLogin',
  async (formData, thunkAPI) => {
    try {
      const config = {
        Headers: {'Content-Type': 'application/json'},
      };
      const url = `${Baseurl}/api/v1/employees/verifyphone`;
      const resp = await axios.post(url, formData, config);
      return resp.data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Pls check your information');
    }
  },
);
const ClientAuthenticationSlice = createSlice({
  name: 'clientAuth',
  initialState,
  reducers: {
    signOut(state) {
      // AsyncStorage.removeItem('loginData');
      state.isAuth = false;
      state.clientId = '';
        state.email = '';
        state.name = '';
        state.role = '';
        state.loginData = '';
        state.availibilityStatus= false;
        state.totalCars=[];
        state.notifications=[];
    },
  },
  extraReducers:(builder)=>{
    builder
    .addCase(employeeLoginBypassword.pending,(state)=>{
      state.clientLoading=true
    })
    .addCase(employeeLoginBypassword.fulfilled,(state,action)=>{
      state.isAuth = true;
      // state.clientId = action.payload.client._id;
      // state.email = action.payload.client.email;
      // state.name = action.payload.client.name;
      // state.role = action.payload.client.role;
      state.loginData = action.payload.client;
      // state.clientLoading=false
      localStorage.setItem("clientLoginData", JSON.stringify(state.loginData));
    })
    .addCase(employeeLoginBypassword.rejected,(state)=>{
      state.clientLoading=true
    })
    .addCase(getCarModels.pending,(state)=>{
      state.isCarLoading=true
    })
    .addCase(getCarModels.fulfilled,(state,action)=>{
      state.totalCars=action.payload.carmodels
      state.isCarLoading=false
    })
    .addCase(getCarModels.rejected,(state)=>{
      state.isCarLoading=true
    })
  }
});

export const clientActions = ClientAuthenticationSlice.actions;
export default ClientAuthenticationSlice.reducer;
